import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useMeasure } from 'react-use';

import Icon from 'atoms/Icons/Icon';
import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';

// Quarks
import Flex from 'quarks/Flex';

import useResourceCardDeckPosts from 'components/CardDeck/utils/useResourceCardDeckPosts';
import ResourceCard from 'components/Cards/ResourceCard';

import useSwipeable from 'utils/useSwipeable';

type ComponentCardDeckProps = {
  backgroundIsDark?: boolean;
  pageType?: string;
  currentPostId?: string;
};

const ComponentCardDeck: FC<ComponentCardDeckProps> = ({ backgroundIsDark, currentPostId, pageType, ...props }) => {
  const { blogs, cases, eguides, infographics, news, podcasts, reports, templates, tipsTools, webinars, videos } =
    useResourceCardDeckPosts(currentPostId);

  const dataMap = {
    Blog: blogs,
    'Case Studies': cases,
    Eguides: eguides,
    Infographics: infographics,
    News: news,
    Podcast: podcasts,
    Reports: reports,
    Templates: templates,
    'Tips and Tools': tipsTools,
    Videos: videos,
    Webinars: webinars,
  };

  const posts = dataMap[pageType]?.nodes || [];
  const filteredPosts = posts.filter((post: any) => post.contentful_id !== currentPostId).slice(0, 6);
  const length = filteredPosts?.length || 0;
  const [cardRef, { width: cardWidth }] = useMeasure();
  const [activeIndex, setActive, cardContainerRef] = useSwipeable(length);
  const isDesktop = useMediaQuery(media.lg);
  const showArrows = (!isDesktop && length > 1) || length > 3;

  return (
    <Flex flexDirection="column" gap="48px" overflowX="hidden" overflowY="hidden" {...props}>
      <Flex flexDirection="column" gap="16px">
        {filteredPosts && (
          <Flex
            gap="30px"
            justifyContent="flex-start"
            transform={`translateX(-${activeIndex * cardWidth + activeIndex * 30}px)`}
            transition="transform 400ms cubic-bezier(0.215, 0.610, 0.355, 1.000)"
            minWidth={`calc(100% * ${length} + (30px * ${length - 1}))`}
            marginBottom={length > 3 ? 0 : 48}
            ref={cardContainerRef}
          >
            {filteredPosts?.slice(0, length || 6)?.map(eguide => (
              <Flex key={eguide?.id} ref={cardRef} width="100%" maxWidth="100%" sm={{ maxWidth: '370px' }}>
                <ResourceCard pageType={pageType} backgroundIsDark={backgroundIsDark} {...eguide} />
              </Flex>
            ))}
          </Flex>
        )}
        {showArrows && (
          <Flex gap="12px" justifyContent="flex-end">
            <Flex
              borderRadius="6px"
              backgroundColor="navy-50"
              width="36px"
              height="36px"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => (activeIndex === 0 ? setActive(length - 1) : setActive(activeIndex - 1))}
            >
              <Icon id="arrow-left" fill={color.gray[900]} size={16} />
            </Flex>
            <Flex
              borderRadius="6px"
              backgroundColor="navy-50"
              width="36px"
              height="36px"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => (activeIndex === length - 1 ? setActive(0) : setActive(activeIndex + 1))}
            >
              <Icon id="arrow-right" fill={color.gray[900]} size={16} />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ComponentCardDeck;

import { graphql, useStaticQuery } from 'gatsby';

const useResourceCardDeckPosts = () => {
  const {
    blogs,
    cases,
    eguides,
    infographics,
    landingPageTemplate,
    news,
    podcasts,
    reports,
    templates,
    tipsTools,
    webinars,
    videos,
  } = useStaticQuery(graphql`
    query {
      blogs: allContentfulTemplateBlog(
        sort: { fields: publishDate, order: DESC }
        filter: { blogType: { ne: "News" } }
        limit: 7
      ) {
        nodes {
          ...contentfulTemplateBlog
        }
      }
      cases: allContentfulTemplateCaseStudy(sort: { fields: publishDate, order: DESC }, limit: 7) {
        nodes {
          ...contentfulTemplateCaseStudy
        }
      }
      eguides: allContentfulTemplateEguides(sort: { fields: publishDate, order: DESC }, limit: 7) {
        nodes {
          ...contentfulTemplateEguides
        }
      }
      templates: allContentfulTemplateTemplates(
        sort: { fields: publishDate, order: DESC }
        filter: { isLandingPageTemplate: { ne: true } }
      ) {
        nodes {
          ...contentfulTemplateTemplates
        }
      }
      landingPageTemplate: allContentfulTemplateTemplates(filter: { isLandingPageTemplate: { eq: true } }) {
        nodes {
          ...contentfulTemplateTemplates
        }
      }
      infographics: allContentfulTemplateResource(
        sort: { fields: publishDate, order: DESC }
        limit: 7
        filter: { resourceType: { eq: "Infographics" } }
      ) {
        nodes {
          ...contentfulTemplateResource
        }
      }
      podcasts: allContentfulTemplatePodcast(sort: { fields: publishDate, order: DESC }, limit: 7) {
        nodes {
          ...contentfulTemplatePodcast
        }
      }
      reports: allContentfulTemplateResource(
        sort: { fields: publishDate, order: DESC }
        limit: 7
        filter: { resourceType: { ne: "Infographics" } }
      ) {
        nodes {
          ...contentfulTemplateResource
        }
      }
      tipsTools: allContentfulTemplateTipsAndTools(sort: { fields: publishDate, order: DESC }, limit: 7) {
        nodes {
          ...contentfulTemplateTipsAndTools
        }
      }
      videos: allContentfulTemplateVideos(
        sort: { fields: publishDate, order: DESC }
        limit: 7
        filter: { resourceType: { eq: "Video" } }
      ) {
        nodes {
          ...contentfulTemplateVideos
        }
      }
      webinars: allContentfulTemplateWebinar(sort: { fields: date, order: DESC }, limit: 7) {
        nodes {
          ...contentfulTemplateWebinar
        }
      }
      news: allContentfulTemplateBlog(
        sort: { fields: publishDate, order: DESC }
        limit: 7
        filter: { blogType: { eq: "News" } }
      ) {
        nodes {
          ...contentfulTemplateBlog
        }
      }
    }
  `);

  return {
    blogs,
    cases,
    eguides,
    infographics,
    landingPageTemplate,
    news,
    podcasts,
    reports,
    templates,
    tipsTools,
    webinars,
    videos,
  };
};

export default useResourceCardDeckPosts;

import { FC } from 'react';

import defaultImage from 'assets/images/banner.jpeg';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

// Quarks
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import ComponentButton from 'components/Button/ComponentButton';

import richTextParser from 'utils/richTextParser';

import type { ContentfulResourceCard } from 'graphqlTypes';

const textWrapCSS = `
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const headingWrapCSS = `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BasicCard: FC<ContentfulResourceCard> = ({
  category,
  featuredImage,
  seo,
  title,
  slug,
  excerpt,
  backgroundIsDark,
  pageType,
  ...props
}) => {
  let baseUrl = '';
  if (pageType === 'News') {
    baseUrl = '/company';
  } else if (pageType === 'Blog') {
    baseUrl = '';
  } else if (pageType === 'Webinars') {
    baseUrl = '';
    pageType = 'webinar';
  } else {
    baseUrl = '/resources';
  }

  const lowercasePageType = pageType?.toLowerCase()?.replace(/ +/g, '-');
  const includesTitle = pageType !== 'Eguides' && title;
  const href = `${baseUrl}/${lowercasePageType}/${slug}`;

  return (
    <Link href={href} textDecoration="none" width="100%">
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        borderRadius="12px"
        overflowX="hidden"
        overflowY="hidden"
        border="1px solid"
        borderColor="gray-200"
        hover={{ boxShadow: 'xxl' }}
        transition="200ms ease-in-out"
        backgroundColor={backgroundIsDark ? 'gray-900' : 'common-white'}
        {...props}
      >
        {seo?.openGraphImage || featuredImage ? (
          <Image
            image={seo?.openGraphImage || featuredImage}
            alt={title || `${pageType} Card Image`}
            objectFit="cover"
            objectPosition="left"
            minHeight="184px"
            maxHeight="184px"
          />
        ) : (
          <Image
            image={{ file: { url: defaultImage } }}
            alt={`${pageType} Card Image`}
            objectFit="cover"
            objectPosition="center"
            minHeight="184px"
            maxHeight="184px"
          />
        )}
        <Flex
          position="relative"
          flexDirection="column"
          justifyContent="flex-start"
          gap="16px"
          height="100%"
          maxHeight="fit-content"
          css={`
            padding: 2rem 2rem 6rem 2rem;
          `}
        >
          {category && (
            <Flex
              borderRadius="16px"
              backgroundColor="green-50"
              paddingX={12}
              paddingY={4}
              fontSize="textXs"
              lineHeight="textXs"
              textColor="green-900"
              fontWeight="semiBold"
              width="fit-content"
              data-cy="category"
            >
              {category}
            </Flex>
          )}
          <Flex flexDirection="column" gap="12px">
            {includesTitle && (
              <Heading
                as="h4"
                textStyle="h5"
                data-cy="title"
                textColor={backgroundIsDark ? 'common-white' : 'gray-900'}
                css={headingWrapCSS}
              >
                {title}
              </Heading>
            )}
            {excerpt ? (
              <Text textStyle="textXs" textColor={backgroundIsDark ? 'gray-200' : 'gray-900'} css={textWrapCSS}>
                {richTextParser(excerpt)}
              </Text>
            ) : (
              <Text textStyle="textSm" textColor={backgroundIsDark ? 'gray-200' : 'gray-900'} css={textWrapCSS}>
                Your business credit score is an essential figure to estimate the creditworthiness of your business. Let
                us walk you through the credit scoring process.
              </Text>
            )}
          </Flex>
          <Flex
            gap="4px"
            alignItems="center"
            css={`
              position: absolute;
              bottom: 20px;

              button,
              a {
                padding-left: 0;
              }
            `}
          >
            <ComponentButton link={href} label="Read more" buttonColor="Gray 900 Link" />
            <Icon id="chevron-right" fill={color.gray[900]} size={20} />
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default BasicCard;

import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import circleImg from 'assets/images/circle-ring.svg';
import { contentfulProps } from 'assets/mockData/emptyTypes';

import { media } from 'atoms/breakpoints/breakpoints';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image from 'quarks/Image';
import Text from 'quarks/Text';

import Section from 'molecules/Section/Section';

import ComponentButton from 'components/Button/ComponentButton';
import ComponentCardDeck from 'components/CardDeck/ResourceCardDeck';
import ComponentConversionPanel from 'components/ConversionPanel/ConversionPanel';
import ComponentHeading from 'components/Heading/Heading';

import type { ContentfulComponentConversionPanel, ContentfulComponentHeading, ContentfulMetaSeo } from 'graphqlTypes';
import type { FC } from 'react';

type DataProps = {
  blogDetailCardDeckHeading: ContentfulComponentHeading;
  blogDetailConversionPanel: ContentfulComponentConversionPanel;
  seo: ContentfulMetaSeo;
};

const NotFoundPage: FC<PageProps<DataProps>> = ({
  data: { blogDetailCardDeckHeading, blogDetailConversionPanel, seo },
  location,
}) => (
  <Layout slug={location.pathname} seo={seo}>
    <Section id={contentfulProps.id} backgroundColor="Navy 900">
      <Flex flexDirection="column" alignItems="center" gap="24px" width="100%" maxWidth="770px" marginX="auto">
        <Heading
          marginBottom={8}
          textColor="common-white"
          fontWeight="semiBold"
          fontFamily="secondaryFont"
          css={`
            font-size: 150px;
            line-height: 130px;
            ${media.md} {
              font-size: 250px;
              line-height: 220px;
            }
          `}
        >
          404
        </Heading>
        <Text
          fontSize="h6"
          lineHeight="h6"
          textColor="common-white"
          fontWeight="bold"
          md={{ fontSize: 'h4', lineHeight: 'h4' }}
          lg={{ fontSize: 'h3', lineHeight: 'h3' }}
        >
          Oops! This page doesn’t exist!
        </Text>
        <ComponentButton label="Go back home" link="/" />
      </Flex>
      <Flex width="20%" position="absolute" top="0" right="0" zIndex="-1">
        <Image alt="Circle Ring SVG Image" image={{ file: { url: circleImg } }} width="100%" />
      </Flex>
    </Section>
    <Section
      id="card-deck-blog"
      backgroundColor="Gray 50"
      paddingTopDesktop="160"
      paddingBottomDesktop="32"
      paddingTopTablet="96"
      paddingBottomTablet="32"
      paddingTopMobile="64"
      paddingBottomMobile="24"
    >
      <Flex
        width="100%"
        height="32px"
        position="absolute"
        top="0"
        left="0"
        zIndex="-1"
        backgroundColor="navy-900"
        md={{ height: '56px' }}
        lg={{ height: '105px' }}
        css={`
          clip-path: polygon(0% 50%, 0% 100%, 100% 0%, 100% 0%, 0% 0%);
        `}
      />
      <ComponentHeading {...contentfulProps} backgroundIsDark={false} {...blogDetailCardDeckHeading} />
    </Section>
    <Section
      id="card-deck-blog"
      backgroundColor="Gray 50"
      paddingTopDesktop="32"
      paddingBottomDesktop="32"
      paddingTopTablet="32"
      paddingBottomTablet="32"
      paddingTopMobile="24"
      paddingBottomMobile="24"
    >
      <ComponentCardDeck pageType="Blog" {...contentfulProps} backgroundIsDark={false} />
    </Section>
    <Section id="conversion-panel-blog">
      <ComponentConversionPanel paddingTop={96} backgroundIsDark={false} {...blogDetailConversionPanel} />
    </Section>
  </Layout>
);

export default NotFoundPage;

export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    blogDetailCardDeckHeading: contentfulComponentHeading(contentful_id: { eq: "Tl24RMI5aVxqSdiqaxUfY" }) {
      ...contentfulComponentHeading
    }
    blogDetailConversionPanel: contentfulComponentConversionPanel(contentful_id: { eq: "5I4iBj0TIvWmjCELmHVbp7" }) {
      ...contentfulComponentConversionPanel
    }
    seo: contentfulMetaSeo(contentful_id: { eq: "1Xr4zrMqv3Gt2PZG74RPP9" }) {
      ...contentfulMetaSeo
    }
  }
`;

import { useEffect, useRef, useState } from 'react';

import { handleTouchMove, handleTouchStart } from 'utils/swipeEvent';

const useSwipeable = (length: number, index = 0) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const cardContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const activeItem = cardContainerRef?.current?.children[activeIndex];

    if (activeItem) {
      const handleSwipe = (e: TouchEvent) => {
        const isCheck = handleTouchMove(e) || 0;
        if (isCheck === 1) {
          setActiveIndex(activeIndex === length - 1 ? 0 : activeIndex + 1);
        }
        if (isCheck === -1) {
          setActiveIndex(activeIndex === 0 ? length - 1 : activeIndex - 1);
        }
      };

      activeItem.addEventListener('touchstart', handleTouchStart, false);
      activeItem.addEventListener('touchmove', handleSwipe, false);

      return () => {
        activeItem.removeEventListener('touchstart', handleTouchStart);
        activeItem.removeEventListener('touchmove', handleSwipe);
      };
    }
  }, [activeIndex]);

  return [activeIndex, setActiveIndex, cardContainerRef];
};

export default useSwipeable;

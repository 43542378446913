import { TouchEvent } from 'react';

let xDown: number | null = null;
let yDown: number | null = null;

export const getTouches = (evt: TouchEvent) => evt.touches || evt.originalEvent.touches;

export const handleTouchStart = (event: TouchEvent) => {
  const firstTouch = getTouches(event)[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
};

export const handleTouchMove = (event: TouchEvent) => {
  if (!xDown || !yDown) {
    return;
  }
  let isState = 0;

  const xUp = event.touches[0].clientX;
  const yUp = event.touches[0].clientY;

  const xDiff = xDown - xUp;
  const yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    /* most significant*/
    if (xDiff > 0) {
      isState = 1;
    } else {
      isState = -1;
    }
  } else if (yDiff > 0) {
    /* down swipe */
  } else {
    /* up swipe */
  }
  /* reset values */
  xDown = null;
  yDown = null;

  return isState;
};
